import React, { Component, Suspense } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationTriangle, faAward, faGavel, faCheckCircle, faCircle, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import Card from './Card';
import Loading from '../components/Loading';
import Collection from '../collections/Collection';
import FavoriteButton from '../components/FavoriteButton';
import Section from '../components/Section';
import RefreshButton from '../components/RefreshButton';
import HistoryButton from './HistoryButton';

import api from '../util/api';
import utils from '../util/utils';
import login from '../util/login';
import favorite from '../util/favorite';

import armoryIcon from '../icons/armory.png';
import raiderioIcon from '../icons/raiderio.png';
import wowprogressIcon from '../icons/wowprogress.png';
import warcraftlogsIcon from '../icons/warcraftlogs.png';
import simplearmoryIcon from '../icons/simplearmory.png';
import twitchIcon from '../icons/twitch.png';
import youtubeIcon from '../icons/youtube.png';
import twitterIcon from '../icons/twitter.png';

const History = React.lazy(() => import('./History'));
const Distribution = React.lazy(() => import('./Distribution'));

class Character extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMore: false,
            loading: false,
            updatefailed: false,
            message: null,
            character: null,
            account: null,
            scores: null,
            leaderboardstats: null,
            links: {}
        };
    }

    componentDidMount() {
        this.lookup(false);
    }

    componentDidUpdate(previous) {
        if (this.props.region === previous.region && this.props.realm === previous.realm && this.props.character === previous.character) return;
        this.lookup(false);
    }

    lookup = async (refresh) => {
        this.setState({ loading: true, updatefailed: false });

        try {
            const response = await api.get('characters/' + encodeURIComponent(this.props.region) + '/' + encodeURIComponent(this.props.realm) + '/' + encodeURIComponent(this.props.character));
            if (response.status !== 200) throw new Error('HTTP Failed');

            const json = await response.json();
            if (!refresh && json.message === 'Character needs update') {
                return this.queue(this.props.region, this.props.realm, this.props.character);
            } else if (json.message) {
                this.setState({ loading: false, message: json.message, character: null, account: null, scores: null, links: {} });
            } else if (json.errorMessage) {
                throw new Error(json.errorMessage);
            } else if (refresh && this.state.character?.updated === json.character?.updated) {
                // refresh failed
                this.setState({ loading: false, updatefailed: true });
            } else {
                const links = {
                    armory: 'https://worldofwarcraft.com/character/' + encodeURIComponent(json.character.region) + '/' + encodeURIComponent(json.character.realmSlug) + '/' + encodeURIComponent(json.character.name),
                    raiderio: 'https://raider.io/characters/' + encodeURIComponent(json.character.region) + '/' + encodeURIComponent(json.character.realmSlug) + '/' + encodeURIComponent(json.character.name),
                    wowprogress: 'https://www.wowprogress.com/character/' + encodeURIComponent(json.character.region) + '/' + encodeURIComponent(json.character.realmSlug) + '/' + encodeURIComponent(json.character.name),
                    warcraftlogs: 'https://www.warcraftlogs.com/character/' + encodeURIComponent(json.character.region) + '/' + encodeURIComponent(json.character.realmSlug) + '/' + encodeURIComponent(json.character.name),
                    simplearmory: 'https://simplearmory.com/#/' + encodeURIComponent(json.character.region) + '/' + encodeURIComponent(json.character.realmSlug) + '/' + encodeURIComponent(json.character.name),
                    self: '/characters/' + encodeURIComponent(json.character.region) + '/' + encodeURIComponent(json.character.realmSlug) + '/' + encodeURIComponent(json.character.name),
                    guild: json.character.guildName ? '/leaderboards/completion-score/' + encodeURIComponent(json.character.region) + '/' + encodeURIComponent(json.character.guildRealm) + '/' + encodeURIComponent(json.character.guildName) : null
                };

                this.setState({ loading: false, message: null, character: json.character, account: json.account, scores: json.scores, links });

                // TODO better way to bubble up the scores
                this.props.onToggle('scores', { split: this.props.split, refresh, scores: json.scores, character: json.character });
            }
        } catch (e) {
            this.setState({ loading: false, message: 'Error!', character: null, account: null, scores: null, links: {} });
        }
    }

    queue = async (region, realm, name) => {
        const response = await api.post('queue/add', [{ region, realm, character: name }]);
        const json = await response.json();
        return this.queueWait(json[0]);
    }

    queueWait = async (token) => {
        while (true) {
            await utils.sleep(10000);

            const response = await api.get('queue/status/' + encodeURIComponent(token));
            const json = await response.json();
            if (json.status === 'DONE') return this.lookup(true);

            this.setState({ loading: json.position || true });
        }
    }

    refresh = async (region, realm, name) => {
        if (region && realm && name) await this.lookup(true);
    }

    toggleMore = (event) => {
        const showMore = this.state.showMore ? false : event.currentTarget;
        this.setState({ showMore });
    }

    buttonHistory = (duration) => {
        this.props.onToggle('history', this.props.collection.historyCategory, duration);
    }

    buttonChart = (type) => {
        this.props.onToggle('chart', type);
    }

    point = (category, weight, amount) => {
        return (
            <tr>
                <td className="align-middle">{utils.getFriendlyCategoryName(category)}</td>
                <td className="text-right">
                    {utils.formatScore(weight * amount)}
                </td>
            </tr>
        );
    }

    breakdown = (category) => {
        if (!category?.startsWith('completion-')) return null;

        const obtainable = category.endsWith('-obtainable') ? '-obtainable' : '';
        const count = category.includes('-count');

        const petcat = count ? 'pets' : 'pets-score';
        const achcat = count ? 'achievements' : 'achievement-points';

        // don't always have achievement/legacy/feats scores, so calculate it
        let fake = this.state.scores[category];
        if (category.startsWith('completion-count')) {
            fake -= this.state.scores['account-mounts' + obtainable];
            fake -= this.state.scores['pets' + obtainable];
            fake -= this.state.scores['account-titles' + obtainable];
            fake -= this.state.scores['account-reputations' + obtainable];
            fake -= this.state.scores['account-recipes' + obtainable];
            fake -= this.state.scores['account-quests' + obtainable];
            fake -= this.state.scores['account-toys' + obtainable];
            fake -= this.state.scores['account-appearance-sources'];
        }

        return (
            <div className="row justify-content-center mb-3">
                <div className={this.props.split ? 'col-12' : 'col-12 col-md-12 col-lg-6 col-xl-4'}>
                    <div className="card rounded">
                        <table className="table table-hover m-0">
                            <thead className="thead-dark">
                                <tr><th colSpan={2}>{utils.getFriendlyCategoryName(category)} Breakdown</th></tr>
                            </thead>
                            <tbody>
                                {this.point(achcat, count ? 1 : 3, count ? fake : this.state.scores['achievement-points'])}
                                {this.point('account-mounts', count ? 1 : 100, this.state.scores['account-mounts' + obtainable])}
                                {this.point(petcat, 1, this.state.scores[petcat + obtainable])}
                                {this.point('account-titles', count ? 1 : 100, this.state.scores['account-titles' + obtainable])}
                                {this.point('account-reputations', count ? 1 : 100, this.state.scores['account-reputations' + obtainable])}
                                {this.point('account-recipes', count ? 1 : 10, this.state.scores['account-recipes' + obtainable])}
                                {this.point('account-quests', count ? 1 : 10, this.state.scores['account-quests' + obtainable])}
                                {this.point('account-toys', count ? 1 : 100, this.state.scores['account-toys' + obtainable])}
                                {this.point('account-appearance-sources', 1, this.state.scores['account-appearance-sources'])}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    myself = () => {
        return login.account() === this.state.character.account;
    }

    showCollectedBy = () => {
        const c = this.props.collection;
        if (c.showAlts || c.showManuscripts || c.showAppearancesources) return false; // never show on these tabs

        if (favorite.get('favorites').getFavorites()?.length) return true; // always show if favorites are available

        // only show certain tabs if looking at own character
        if (!this.myself()) return false;

        return c.showTitles || c.showReputations || c.showRecipes || c.showQuests || c.showHunterpets || c.showSoulshapes || c.showSkips;
    }

    onCollectedBy = async (collection, id) => {
        const tokey = c => utils.clean(c.region) + '#' + utils.slug(c.realm) + '#' + utils.clean(c.name || c.character);

        const payload = {
            favorites: favorite.get('favorites').getFavorites()?.map(tokey) || []
        };

        const c = this.props.collection;
        if (c.showTitles || c.showReputations || c.showRecipes || c.showQuests || c.showHunterpets || c.showSoulshapes || c.showSkips) {
            // only show My Characters for non-account-wide collections
            if (this.myself()) {
                payload.login = tokey(this.props);
            }
        }
        if (c.showSoulshapes || c.showSkips) {
            collection = 'quests'; // virtual
        }

        const response = await api.post('tooltip/' + encodeURIComponent(collection) + '/' + encodeURIComponent(id), payload);
        if (response.status !== 200) throw new Error('HTTP Failed');

        const json = await response.json();

        // sort the favorites list
        const pos = {};
        payload.favorites?.forEach((f, idx) => pos[f] = idx);
        json.favorites?.forEach(f => f.key = tokey(f));
        json.favorites?.sort((a, b) => (pos[a.key] || 0) - (pos[b.key] || 0));

        return json;
    }

    renderCard(category, navUrl) {
        const ingame = category.includes('mounts') ? this.state.character['character-mounts-journal'] : null;
        return <Card key={category} selectedCategory={this.props.collection.historyCategory} navUrl={navUrl} split={this.props.split} score={this.state.scores[category]} myscore={this.state.character[category]} ingame={ingame} category={category} region={this.props.region} realm={this.state.character.realm} realmSlug={this.state.character.realmSlug} ranking={this.props.collection?.rankings?.[category]} />;
    }

    renderStats() {
        const tpc = this.props.collection;

        const lookup = {};
        tpc.stats.forEach(stat => lookup[stat.key] = stat);

        return <React.Fragment key="stats">
            <div className="m-3 card">
                <table className="table table-hover m-0">
                    <thead className="thead-dark">
                        <tr><th>Statistic</th><th width="10%">This&nbsp;Character</th><th width="10%">Account</th></tr>
                    </thead>
                    <tbody>
                        {utils.STATS.filter(stat => !stat.achievement).map(stat =>
                            <tr key={stat.key}>
                                <td>{stat.name}</td>
                                <td>{utils.formatScore(lookup[stat.key]?.myself || 0)}</td>
                                <td>{utils.formatScore(lookup[stat.key]?.sum || 0)}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="m-3 card">
                <table className="table table-hover m-0">
                    <thead className="thead-dark">
                        <tr><th>Achievement Progress</th><th width="10%"></th><th width="10%">Account</th></tr>
                    </thead>
                    <tbody>
                        {utils.STATS.filter(stat => stat.achievement).map(stat =>
                            <tr key={stat.key}>
                                <td><a href={'https://www.wowhead.com/achievement=' + stat.achievement}>{stat.name}</a></td>
                                <td></td>
                                <td>{utils.formatScore(lookup[stat.key]?.max || 0)}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </React.Fragment>;
    }

    navUrl() {
        const parts = document.location.pathname.split('/');
        if (parts.length >= 9) parts.length = 9; // compare url
        else if (parts.length >= 5) parts.length = 5; // standard url
        return parts.join('/');
    }

    render() {
        let character = [];

        if (this.state.loading) {
            const numeric = typeof this.state.loading === 'number';
            character.push(<Loading key="loading" message={numeric ? ('#' + this.state.loading + ' in queue') : 'Loading...'}/>);
        }

        if (this.state.message) {
            const buttons = (
                <div className="my-auto d-flex">
                    <div className="flex-fill"></div>
                    <div><RefreshButton region={this.props.region} realm={this.props.realm} character={this.props.character} message="Update" onRefresh={this.refresh} /></div>
                </div>
            );

            character.push(
                <Section key="message" title={this.state.message || 'Error'} buttons={buttons}>
                    <div>
                        Please make sure that:
                        <ul>
                        <li>You have tried using the <i>Update</i> button above.</li>
                        <li>You have properly spelled the character's name, including accented characters.</li>
                        <li>Your character is at least level 10. Blizzard's API does not show low-level characters.</li>
                        <li>You have logged in and out of the character recently. Blizzard's API does not show inactive characters.</li>
                        <li>If there have been any recent patches or realm connections, you have logged in and out of the character afterwards.</li>
                        </ul>
                    </div>
                    <div>Sometimes there are temporary errors with the Blizzard API. If all else fails, please wait a few hours and try again.</div>
                </Section>
            );
        }

        if (this.state.character) {
            let name, guild;

            if (this.props.split) {
                name = <Link to={this.state.links.self} className={'class-' + this.state.character.class}>{this.state.character.name}</Link>;
            } else {
                name = <span className={'class-' + this.state.character.class}>{this.state.character.name}</span>;
                document.title = this.state.character.name + ' @ ' + this.props.region.toUpperCase() + '-' + this.state.character.realm + ' | Character Profile | Data for Azeroth | World of Warcraft Leaderboards for Collectors';
            }

            if (this.state.links.guild) guild = <Link to={this.state.links.guild} className={'faction-' + this.state.character.faction}>❮{this.state.character.guildName}❯</Link>;

            const now = new Date();
            const tpc = this.props.collection;
            const navUrl = this.navUrl();
            const canUpdate = !this.state.character.updated || (new Date(this.state.character.updated) < now.setMinutes(now.getMinutes() - 5));
            const obtainable = tpc.showAccountObtainable || tpc.showCharacterObtainable || tpc.showProfessionRankingsObtainable ? '-obtainable' : '';

            const buttons =
                <div className="d-flex my-auto">
                    <div className="flex-fill"></div>
                    <div><RefreshButton disabled={!canUpdate} region={this.props.region} realm={this.props.realm} character={this.props.character} timestamp={this.state.character.updated} message={this.state.updatefailed ? 'Update Failed' : null} onRefresh={this.refresh} /></div>
                    <div className="ml-2"><FavoriteButton region={this.state.character.region} realm={this.state.character.realm} name={this.state.character.name} class={this.state.character.class} thumbnail={this.state.character.thumbnail} /></div>
                </div>;

            const icons =
                <div className="d-flex my-auto">
                    {this.state.account.twitch ? <a href={'https://twitch.tv/' + encodeURIComponent(this.state.account.twitch)} target="_blank" rel="noopener noreferrer" title="Twitch Channel"><img src={twitchIcon} alt="Twitch Channel" className="ml-2" style={{ height: '1.5rem', width: '1.5rem' }} /></a> : null}
                    {this.state.account.youtube ? <a href={'https://youtube.com/' + this.state.account.youtube} target="_blank" rel="noopener noreferrer" title="YouTube Channel"><img src={youtubeIcon} alt="YouTube Channel" className="ml-2" style={{ height: '1.5rem', width: '1.5rem' }} /></a> : null}
                    {this.state.account.twitter ? <a href={'https://twitter.com/' + encodeURIComponent(this.state.account.twitter)} target="_blank" rel="noopener noreferrer" title="Twitter"><img src={twitterIcon} alt="Twitter" className="ml-2" style={{ height: '1.5rem', width: '1.5rem' }} /></a> : null}
                    <a href={this.state.links.armory} target="_blank" rel="noopener noreferrer" title="Armory Profile"><img src={armoryIcon} alt="Armory Profile" className="ml-2" style={{ height: '1.5rem', width: '1.5rem' }} /></a>
                    <a href={this.state.links.raiderio} target="_blank" rel="noopener noreferrer" title="Raider.io Profile"><img src={raiderioIcon} alt="Raider.io Profile" className="ml-2" style={{ height: '1.5rem', width: '1.5rem' }} /></a>
                    <a href={this.state.links.wowprogress} target="_blank" rel="noopener noreferrer" title="WoWProgress Profile"><img src={wowprogressIcon} alt="WoWProgress Profile" className="ml-2" style={{ height: '1.5rem', width: '1.5rem' }} /></a>
                    <a href={this.state.links.warcraftlogs} target="_blank" rel="noopener noreferrer" title="Warcraft Logs Profile"><img src={warcraftlogsIcon} alt="Warcraft Logs Profile" className="ml-2" style={{ height: '1.5rem', width: '1.5rem' }} /></a>
                    <a href={this.state.links.simplearmory} target="_blank" rel="noopener noreferrer" title="Simple Armory Profile"><img src={simplearmoryIcon} alt="Simple Armory Profile" className="ml-2" style={{ height: '1.5rem', width: '1.5rem' }} /></a>
                </div>;

            const subtitle =
                <div style={this.props.split ? { height: '3rem', overflow: 'hidden' } : null}>
                    Level {this.state.character.level} | <Tooltip title={'Item Level ' + this.state.character.averageItemLevel}><span><FontAwesomeIcon icon={faGavel} /> {this.state.character.averageItemLevel}</span></Tooltip> | {guild} {this.props.region.toUpperCase()}-{this.state.character.realm}
                    {this.state.account.thanks ? <Tooltip title={'Thank you for the support, ' + this.state.character.name + '!'}><a href="https://www.patreon.com/shoogen" target="external" className="ml-2"><FontAwesomeIcon icon={faAward} /></a></Tooltip> : null}
                </div>;

            const more = <>
                <NavLink to={navUrl + '/heirlooms'} exact className="btn btn-primary">Heirlooms</NavLink>
                <NavLink to={navUrl + '/hunterpets'} exact className="btn btn-primary">Hunter Pets</NavLink>
                <NavLink to={navUrl + '/soulshapes'} exact className="btn btn-primary">Soulshapes</NavLink>
                <NavLink to={navUrl + '/manuscripts'} exact className="btn btn-primary">Manuscripts</NavLink>
                <NavLink to={navUrl + '/skips'} exact className="btn btn-primary">Raid Skips</NavLink>
                {login.contributor() ? <NavLink to={navUrl + '/alts'} exact className="btn btn-primary">Alts</NavLink> : null}
            </>;

            character.push(
                <Section key="character" title={name} subtitle={subtitle} icon={this.state.character.thumbnail ? 'https://render.worldofwarcraft.com/' + this.props.region.toLowerCase() + '/character/' + this.state.character.thumbnail : null} icons={icons} buttons={buttons}>
                    <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' mb-3 mr-3 flex-wrap'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <NavLink to={navUrl + '/account-rankings' + obtainable} exact className="btn btn-primary" isActive={match => match || tpc.showAccount}>Account</NavLink>
                        <NavLink to={navUrl + '/character-rankings' + obtainable} exact className="btn btn-primary">Character</NavLink>
                        <NavLink to={navUrl + '/profession-rankings' + obtainable} exact className="btn btn-primary">Profession</NavLink>
                        <NavLink to={navUrl + '/stats'} exact className="btn btn-primary">Stats</NavLink>
                    </div>
                    <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' mb-3 flex-wrap'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                        <NavLink to={navUrl + '/achievements'} exact className="btn btn-primary">Achievements</NavLink>
                        <NavLink to={navUrl + '/mounts'} exact className="btn btn-primary">Mounts</NavLink>
                        <NavLink to={navUrl + '/pets'} exact className="btn btn-primary">Pets</NavLink>
                        <NavLink to={navUrl + '/titles'} exact className="btn btn-primary">Titles</NavLink>
                        <NavLink to={navUrl + '/reputations'} exact className="btn btn-primary">Reputations</NavLink>
                        <NavLink to={navUrl + '/recipes'} exact className="btn btn-primary">Recipes</NavLink>
                        <NavLink to={navUrl + '/quests'} exact className="btn btn-primary">Quests</NavLink>
                        <NavLink to={navUrl + '/toys'} exact className="btn btn-primary">Toys</NavLink>
                        <NavLink to={navUrl + '/appearances' + (tpc.showAppearancesources ? 'ources' : '')} exact className="btn btn-primary">Appearances</NavLink>
                        {window.bootstrap.md.matches ? <button type="button" className="btn btn-primary" onClick={this.toggleMore}>More <FontAwesomeIcon icon={this.state.showMore ? faChevronUp : faChevronDown} /></button> : null}
                    </div>

                    {window.bootstrap.md.matches ?
                        <Popper open={Boolean(this.state.showMore)} anchorEl={this.state.showMore} placement="bottom-start">
                            <div className="card mt-1">
                                <div className="btn-group-vertical">{more}</div>
                                <ClickAwayListener onClickAway={this.toggleMore}><div/></ClickAwayListener>
                            </div>
                        </Popper>
                    :
                        <div className="btn-group-vertical w-100 mb-3 flex-wrap" style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                            {more}
                        </div>
                    }

                    {tpc.showHistory ? 
                        <div className="p-2 rounded rankings d-flex align-items-center" style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                            {utils.getDescription(tpc.historyCategory)}
                        </div>
                        :
                        <>
                            {tpc.showAccount || tpc.showAccountObtainable ? <div className="p-2 rounded rankings" style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}><FontAwesomeIcon icon={faInfoCircle} /> Account-Wide Rankings combine data from all your characters, including both Alliance and Horde</div> : null}
                            {tpc.showProfessionRankings || tpc.showProfessionRankingsObtainable ? <div className="p-2 rounded rankings" style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}><FontAwesomeIcon icon={faInfoCircle} /> Recipes by Profession Rankings combine data from all your characters, including both Alliance and Horde</div> : null}
                            {tpc.showCharacter || tpc.showCharacterObtainable ? <div className="p-2 rounded rankings" style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}><FontAwesomeIcon icon={faInfoCircle} /> Character-Specific Rankings select your best individual character for each statistic, which may not be this character</div> : null}
                            {(tpc.showAccount || tpc.showAccountObtainable || tpc.showCharacter || tpc.showCharacterObtainable || tpc.showProfessionRankings || tpc.showProfessionRankingsObtainable) && !this.props.split ?
                                <>
                                    {(this.state.character.realmSlug !== this.state.account.realm) ? <div className="p-2 mt-3 rounded rankings"><FontAwesomeIcon icon={faExclamationTriangle} /> This character was not detected on your main realm and will not be shown in the leaderboards. However, here is how you would rank if it was your main. <Link className="small" to="/about#main-character">More Info...</Link></div> : null}
                                    {this.state.account.exclude ? <div className="p-2 mt-3 rounded rankings"><FontAwesomeIcon icon={faExclamationTriangle} /> This character appears to have account-wide achievements disabled and has therefore been excluded from statistic calculations. <Link className="small" to="/about#alt-detection">More Info...</Link></div> : null}
                                </>
                            : null}

                            {tpc.showAccount || tpc.showAccountObtainable || tpc.showCharacter || tpc.showCharacterObtainable || tpc.showProfessionRankings || tpc.showProfessionRankingsObtainable ?
                                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' mt-3 flex-wrap'} style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                    {tpc.showAccount ? <Link to={navUrl + '/account-rankings-obtainable'} className="btn btn-primary"><FontAwesomeIcon icon={faCircle} /> Obtainable Only</Link> : null}
                                    {tpc.showAccountObtainable ? <Link to={navUrl + '/account-rankings'} className="btn btn-primary active"><FontAwesomeIcon icon={faCheckCircle} /> Obtainable Only</Link> : null}
                                    {tpc.showCharacter ? <Link to={navUrl + '/character-rankings-obtainable'} className="btn btn-primary"><FontAwesomeIcon icon={faCircle} /> Obtainable Only</Link> : null}
                                    {tpc.showCharacterObtainable ? <Link to={navUrl + '/character-rankings'} className="btn btn-primary active"><FontAwesomeIcon icon={faCheckCircle} /> Obtainable Only</Link> : null}
                                    {tpc.showProfessionRankings ? <Link to={navUrl + '/profession-rankings-obtainable'} className="btn btn-primary"><FontAwesomeIcon icon={faCircle} /> Obtainable Only</Link> : null}
                                    {tpc.showProfessionRankingsObtainable ? <Link to={navUrl + '/profession-rankings'} className="btn btn-primary active"><FontAwesomeIcon icon={faCheckCircle} /> Obtainable Only</Link> : null}
                                </div>
                            : null}
                        </>
                    }

                    {!tpc.showAccount ? null :
                        <>
                        <div className="row justify-content-center">
                            {this.renderCard('completion-score', navUrl)}
                        </div>
                        <div className="row justify-content-center mt-3">
                            {this.renderCard('achievement-points', navUrl)}
                            {this.renderCard('account-mounts', navUrl)}
                            {this.renderCard('pets-score', navUrl)}
                            {this.renderCard('account-titles', navUrl)}
                            {this.renderCard('account-reputations', navUrl)}
                            {this.renderCard('account-recipes', navUrl)}
                            {this.renderCard('account-quests', navUrl)}
                            {this.renderCard('account-toys', navUrl)}
                            {this.renderCard('account-appearance-sources', navUrl)}
                        </div>
                        {tpc.showHistory ? null : <div>The following do not directly count toward Completion Score:</div>}
                        <div className="row justify-content-center">
                            {this.renderCard('completion-count', navUrl)}
                            {this.renderCard('achievements', navUrl)}
                            {this.renderCard('feats', navUrl)}
                            {this.renderCard('legacy', navUrl)}
                            {this.renderCard('pets', navUrl)}
                            {this.renderCard('account-appearances', navUrl)}
                            {this.renderCard('alts', navUrl)}
                            {this.renderCard('alts-score', navUrl)}
                            {this.renderCard('honorlevel', navUrl)}
                            {this.renderCard('account-kills', navUrl)}
                        </div>
                        </>
                    }
                    {!tpc.showAccountObtainable ? null :
                        <>
                        <div className="row justify-content-center">
                            {this.renderCard('completion-score-obtainable', navUrl)}
                        </div>
                        <div className="row justify-content-center mt-3">
                            {this.renderCard('achievement-points', navUrl)}
                            {this.renderCard('account-mounts-obtainable', navUrl)}
                            {this.renderCard('pets-score-obtainable', navUrl)}
                            {this.renderCard('account-titles-obtainable', navUrl)}
                            {this.renderCard('account-reputations-obtainable', navUrl)}
                            {this.renderCard('account-recipes-obtainable', navUrl)}
                            {this.renderCard('account-quests-obtainable', navUrl)}
                            {this.renderCard('account-toys-obtainable', navUrl)}
                            {this.renderCard('account-appearance-sources', navUrl)}
                        </div>
                        {tpc.showHistory ? null : <div>The following do not directly count toward Completion Score:</div>}
                        <div className="row justify-content-center">
                            {this.renderCard('completion-count-obtainable', navUrl)}
                            {this.renderCard('pets-obtainable', navUrl)}
                            {this.renderCard('account-appearances-obtainable', navUrl)}
                            {this.renderCard('alts', navUrl)}
                            {this.renderCard('alts-score', navUrl)}
                            {this.renderCard('honorlevel', navUrl)}
                            {this.renderCard('account-kills', navUrl)}
                        </div>
                        </>
                    }
                    {!tpc.showCharacter ? null :
                        <div className="row justify-content-center">
                            {utils.CARDS['character-rankings'].map(key => this.renderCard(key, navUrl))}
                        </div>
                    }
                    {!tpc.showCharacterObtainable ? null :
                        <div className="row justify-content-center">
                            {utils.CARDS['character-rankings-obtainable'].map(key => this.renderCard(key, navUrl))}
                        </div>
                    }
                    {!tpc.showProfessionRankings ? null :
                        <div className="row justify-content-center">
                            {utils.CARDS['profession-rankings'].map(key => this.renderCard(key, navUrl))}
                        </div>
                    }
                    {!tpc.showProfessionRankingsObtainable ? null :
                        <div className="row justify-content-center">
                            {utils.CARDS['profession-rankings-obtainable'].map(key => this.renderCard(key, navUrl))}
                        </div>
                    }

                    {this.breakdown(tpc.historyCategory)}
                    {!tpc.showHistory ? null :
                        <>
                        <Suspense fallback={<Loading message="Loading..." />}>
                            <Distribution category={tpc.historyCategory} data={this.props.collection?.leaderboardstats?.[tpc.historyCategory]} score={this.state.scores?.[tpc.historyCategory]} highlight={utils.classes.find(c => c.key === this.state.character?.class)?.color} />
                        </Suspense>
                        <div className="card rounded mt-3">
                            <div className="card-header font-weight-bold">History</div>
                            <div className="mx-3" style={(this.props.split === 'right') ? { visibility: 'hidden' } : null}>
                                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : '-vertical w-100')  + ' flex-wrap mt-3 mr-3'}>
                                    <HistoryButton id="7days" label="7 Days" active={tpc.historyDuration} onClick={this.buttonHistory} />
                                    <HistoryButton id="30days" label="30 Days" active={tpc.historyDuration} onClick={this.buttonHistory} />
                                    <HistoryButton id="90days" label="90 Days" active={tpc.historyDuration} onClick={this.buttonHistory} />
                                    <HistoryButton id="52weeks" label="1 Year" active={tpc.historyDuration} onClick={this.buttonHistory} />
                                    <HistoryButton id="max" label="Max" active={tpc.historyDuration} onClick={this.buttonHistory} />
                                </div>
                                <div className={'btn-group' + (window.bootstrap.md.matches ? '' : ' w-100')  + ' flex-wrap mt-3'}>
                                    <HistoryButton id="line" label="Line" active={tpc.chartType} onClick={this.buttonChart} />
                                    {this.props.split ? null : <HistoryButton id="table" label="Table" active={tpc.chartType} onClick={this.buttonChart} />}
                                </div>
                            </div>
                            <Suspense fallback={<Loading message="Loading..." />}>
                                <History type={tpc.chartType} series={tpc['history' + tpc.historyDuration]} category={tpc.historyCategory} />
                            </Suspense>
                        </div>
                        </>
                    }

                    <Collection split={this.props.split} key="collection" onToggle={this.props.onToggle} collection={tpc} myself={!this.props.split && this.myself()} onCollectedBy={this.showCollectedBy() ? this.onCollectedBy : null} />
                </Section>
            );

            if (tpc.showStats) character.push(this.renderStats());
        }

        return (
            <div>
                {character}
            </div>
        );
    }
}

export default Character;
