import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faMinusCircle, faExclamationTriangle, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import FactionIcon from '../components/FactionIcon';
import utils from '../util/utils';

import './Progress.scss';

export default class Progress extends Component {
    toggle = () => {
        const collapsed = this.props.collapsed;

        // figure out new total, after collapsing
        let total = this.props.groups.filter(g => !collapsed.has(g.name)).reduce((a, c) => a + c.total, 0);
        total += collapsed.has(this.props.group.name) ? this.props.group.total : -this.props.group.total;

        // set focus to the first element of this group
        let index = this.props.groups.filter(g => !collapsed.has(g.name) && g.start < this.props.group.start).reduce((a, c) => a + c.total, 0);

        // but make sure new index falls within bounds
        if (index >= total) index = total - 1;
        if (index < 0) index = 0;

        this.props.onToggle('collapse', this.props.group.name, index);
    }

    toggleTotal = () => {
        const every = this.props.groups.every(g => this.props.collapsed.has(g.name));
        this.props.onToggle('collapse', every ? [] : this.props.groups.map(g => g.name));
    }

    tooltip = () => {
        const group = this.props.group;
        const tooltip = (
            <>
                {this.props.browse ? null : <>
                    <div className="progress">
                        <div className="progress-bar bg-success" style={{ width: '100%' }}>{utils.formatScore(group.account)} Collected</div>
                    </div>
                    <div className="small">{utils.formatScore(group.character)} Collected on Character</div>
                    <div className="small">{utils.formatScore(group.account - group.character)} Collected on Alt</div>
                </>}

                <div className={'progress' + (this.props.browse ? '' : ' mt-3')}>
                    <div className="progress-bar progress-bar-striped bg-dark" style={{ width: '100%' }}>{utils.formatScore(group.total - group.account - this.unobtainable())} Obtainable</div>
                </div>
                {group.unobtainable3 ? <div className="small"><span className="badge badge-un-3 badge-align"><FontAwesomeIcon icon={faBan} /></span> {utils.formatScore(group.unobtainable3)} Obtainable for Limited Time</div> : null}

                <div className="progress mt-3">
                    <div className="progress-bar progress-bar-striped bg-danger" style={{ width: '100%' }}>{utils.formatScore(this.unobtainable())} Unobtainable</div>
                </div>
                {group.unobtainable1 ? <div className="small"><span className="badge badge-un-1 badge-align"><FontAwesomeIcon icon={faBan} /></span> {utils.formatScore(group.unobtainable1)} Unobtainable</div> : null}
                {group.unobtainable2 ? <div className="small"><span className="badge badge-un-2 badge-align"><FontAwesomeIcon icon={faBan} /></span> {utils.formatScore(group.unobtainable2)} Never Implemented</div> : null}
                {group.unobtainable4 ? <div className="small"><span className="badge badge-un-4 badge-align"><FontAwesomeIcon icon={faBan} /></span> {utils.formatScore(group.unobtainable4)} Bugged</div> : null}
                {group.missing ? <div className="small"><span className="badge badge-1 badge-align"><FontAwesomeIcon icon={faExclamationTriangle} /></span> {utils.formatScore(group.missing)} Missing</div> : null}

                {group.excluded || group.alliance || group.horde || this.props.filtered ? <>
                    <div className="progress mt-3">
                        <div className="progress-bar bg-dark" style={{ width: '100%' }}>Other</div>
                    </div>
                    {group.excluded ? <div className="small"><span className="badge badge-1 badge-align"><FontAwesomeIcon icon={faMinusCircle} /></span> {utils.formatScore(group.excluded)} Excluded</div> : null}
                    {group.alliance ? <div className="small badge-align"><FactionIcon faction={0} /> {utils.formatScore(group.alliance)} Alliance Only</div> : null}
                    {group.horde ? <div className="small badge-align"><FactionIcon faction={1} /> {utils.formatScore(group.horde)} Horde Only</div> : null}
                    {this.props.filtered ? <div className="small">{utils.formatScore(this.props.filtered)} Hidden by Filters</div> : null}
                </> : null}
            </>
        );
        this.setState({ tooltip });
    }

    unobtainable = () => {
        const group = this.props.group;
        return (group.unobtainable1 || 0) + (group.unobtainable2 || 0) + (group.unobtainable4 || 0) + (group.missing || 0);
    }

    render() {
        const group = this.props.group;
        if (!group?.name || !group?.total) return null;

        const character = Math.round(100 * group.character / group.total);
        const alt = Math.round(100 * group.account / group.total) - character;
        const unobtainable = Math.round(100 * this.unobtainable() / group.total);
        const obtainable = 100 - character - alt - unobtainable;

        const collapsed = this.props.total ? this.props.groups.every(g => this.props.collapsed.has(g.name)) : this.props.collapsed.has(group.name);

        let text = null;
        if (this.props.browse) {
            text = utils.formatScore(group.total) + ' Items';
        } else if (this.props.split) {
            const them = group.total - group.account;
            const delta = group.account - them;
            text = utils.formatScore(delta, true) + ' Difference (' + utils.formatScore(group.account, true) + ' / ' + utils.formatScore(-them, true) + ')';
        } else {
            text = utils.formatPercentAndScore('adhoc', group.account, { adhoc: group.total });
        }

        return (
            <div className="progress-grid">
                <button type="button" className="btn btn-dark" onClick={this.props.total ? this.toggleTotal : this.toggle}>{group.name} <FontAwesomeIcon icon={collapsed ? faChevronDown : faChevronUp} /></button>
                <Tooltip title={this.state?.tooltip} onOpen={this.tooltip} placement="bottom-end">
                    <div className="progress bg-transparent">
                        {character ? <div className="progress-bar bg-success" style={{ width: character + '%' }}></div> : null}
                        {alt ? <div className="progress-bar progress-bar-striped bg-success" style={{ width: alt + '%' }}></div> : null}
                        {obtainable ? <div className="progress-bar progress-bar-striped bg-dark" style={{ width: obtainable + '%' }}></div> : null}
                        {unobtainable ? <div className="progress-bar progress-bar-striped bg-danger" style={{ width: unobtainable + '%' }}></div> : null}
                        <div className="m-2" style={{ position: 'absolute' }}>
                            {text}
                            {this.props.filtered ? <> + {utils.formatScore(this.props.filtered)} Hidden by Filters</> : null}
                        </div>
                    </div>
                </Tooltip>
            </div>
        );
    }
}