const LOOKUP = {
    'nil': null,
    'true': true,
    'false': false,
    '0': 0, // to avoid regex
    '1': 1, // to avoid regex
    '2': 2 // to avoid regex
};

// ideally this should be a generic lua parser
// but for now, just cuts out the vars we're looking for
function parse(text) {
    let outline = parse2(text);

    if (outline.ATTAccountWideData) {
        /*
        const characters = parse2(outline.ATTCharacterData);
        for (let key of Object.keys(characters)) {
            const character = parse2(characters[key]);
            characters[key] = {
                name: str(character.name),
                realm: str(character.realm),
                Quests: table(character.Quests)
            };
        }
        */

        const account = parse2(outline.ATTAccountWideData);
        return {
            ATTAccountWideData: {
                Quests: table(account.Quests),
                Sources: table(account.Sources),
                BattlePets: table(account.BattlePets),
                Toys: table(account.Toys)
            }
        };
    }

    if (outline.ManuscriptsJournalExportDB) {
        outline = parse2(outline.ManuscriptsJournalExportDB);
        return {
            ManuscriptsJournalExportDB: {
                learnedManuscripts: table(outline.learnedManuscripts)
            }
        };
    }

    return null;
}

function parse2(text) {
    let depth = 0;

    let start = 0;
    let equals = 0;
    let open = 0;
    let close = 0;

    const result = {};

    for (let i = 0; i < text.length; i++) {
        const c = text[i];

        if (c === '{') {
            if (depth === 0) open = i;
            depth++;
        } else if (c === '}') {
            depth--;
            if (depth === 0) close = i;
        }

        if (depth > 0) continue;

        // root-level only
        if (c === '=') {
            equals = i;
        } else if (c === '\n' && equals) {
            let name = text.substring(start, equals);
            name = name.replace(/["\s[\]]/g, '');

            let value = open ? text.substring(open+1, close) : text.substring(equals+1, i).trim();
            result[name] = value;

            // reset
            start = i+1;
            equals = 0;
            open = 0;
            close = 0;
        }
    }

    return result;
}

/*
function str(text) {
    if (!text) return null;
    return table(text)?.['1'];
}
*/

function table(text) {
    const result = {};

    if (!text) return result;

    text = text.replace(/--.*$/gm, '').trim(); // remove comments
    text.split(/\s*,\s*/).forEach((value, idx) => {
        if (value === '') return; // missing value, sometimes extra comma at the end of array

        let key = idx + 1;

        const mid = value.indexOf('=');
        if (mid > 0) {
            key = value.substring(0, mid).replace(/[["\]]/g, '').trim();
            value = value.substring(mid + 1).trim();
        }

        if (value in LOOKUP) {
            value = LOOKUP[value];
        } else if (/^\d+$/.test(value)) {
            value = parseInt(value);
        } else if (/^".*"$/.test(value)) {
            value = value.substring(1, value.length - 1);
        }

        result[key] = value;
    });

    return result;
}

const lua = { parse };
export default lua;