import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';

import Loading from '../components/Loading';

import login from '../util/login';
import api from '../util/api';
import lua from '../util/lua';

export default class AddonUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false
        };

        // remove old keys
        this.remove('appearances');
    }

    upload = async (event) => {
        if (event.target.files) {
            this.setState({ uploading: true });
            try {
                const parsed = lua.parse(await event.target.files[0].text());

                const mset = new Set();
                (await api.web('manuscripts')).manuscripts.forEach(m => mset.add(m.id + ''));

                if (parsed.ATTAccountWideData) {
                    const quests = parsed.ATTAccountWideData.Quests;
                    this.set('quests', Object.keys(quests).filter(key => quests[key]).map(key => parseInt(key)));
                    this.set('manuscripts', Object.keys(quests).filter(key => mset.has(key) && quests[key]).map(key => parseInt(key)));

                    const appearances = parsed.ATTAccountWideData.Sources;
                    this.set('appearancesources', Object.keys(appearances).filter(key => appearances[key] === 1).map(key => parseInt(key)));

                    const pets = parsed.ATTAccountWideData.BattlePets;
                    this.set('pets', Object.keys(pets).filter(key => pets[key]).map(key => parseInt(key)));

                    const toymap = {};
                    (await api.web('toys')).toys.forEach(toy => toymap[toy.wowheadid] = toy.id);

                    const toys = parsed.ATTAccountWideData.Toys;
                    this.set('toys', Object.keys(toys).filter(key => toys[key] && toymap[key]).map(key => toymap[key]));
                } else if (parsed.ManuscriptsJournalExportDB) {
                    const manuscripts = parsed.ManuscriptsJournalExportDB.learnedManuscripts;
                    this.set('manuscripts', Object.keys(manuscripts).filter(key => mset.has(key) && manuscripts[key]).map(key => parseInt(key)));
                } else {
                    throw new Error('no data');
                }

                this.props.onClose(true);
            } catch (e) {
                alert('Unrecognized SavedVariables file. If you believe this is a bug, please report it to the dataforazeroth discord.');
            }
            this.setState({ uploading: false });
        } else {
            this.remove('quests');
            this.remove('manuscripts');
            this.remove('appearancesources');
            this.remove('pets');
            this.remove('toys');
            this.props.onClose(true);
        }
    }

    set(name, array) {
        localStorage.setItem('collection.' + name + '.' + login.account(), JSON.stringify(array));
        localStorage.setItem('collection.' + name + '.' + login.account() + '.updated', Date.now());
    }

    remove(name) {
        localStorage.removeItem('collection.' + name + '.' + login.account());
        localStorage.removeItem('collection.' + name + '.' + login.account() + '.updated');
    }

    render() {
        const updated = parseInt(localStorage.getItem('collection.' + this.props.category + '.' + login.account() + '.updated'));
        const timestamp = updated ? <Tooltip title={'Last Updated: ' + moment(updated).format('lll')}><span><FontAwesomeIcon icon={faClock} className="mr-1" />{moment(updated).fromNow()}</span></Tooltip> : null;

        return (
            <>
                {this.state.uploading ? <Loading message="Loading..." /> : null}
                <Modal open={this.props.open} onClose={this.props.onClose}>
                    <div className="card rounded" style={{ position: 'absolute', top: '50%', left: '50%', width: '25%', transform: 'translate(-50%, -50%)' }}>
                        <div className="card-header text-center">Upload Addon Data</div>
                        <div className="text-center">
                            <div className="mb-3">
                                You can upload from <a className="class-4" href="https://www.curseforge.com/wow/addons/all-the-things" target="external">ALL THE THINGS</a>
                                {this.props.category === 'manuscripts' ? <> or <a className="class-11" href="https://www.curseforge.com/wow/addons/manuscripts-journal" target="external">Manuscripts Journal</a> </> : <> </>}
                                to help fill in any missing information.
                                This will not count towards the leaderboards, but at least allows you to browse through your collections. <Link className="small" to="/about#upload">More Info...</Link>
                            </div>
                            <div className={'btn-group' + (timestamp ? ' mb-1' : ' mb-3')}>
                                <label className="btn btn-primary m-0">Browse <input type="file" accept=".lua" hidden onChange={this.upload} /></label>
                                <button type="button" className="btn btn-primary" onClick={this.upload}>Clear</button>
                                <button type="button" className="btn btn-primary" onClick={this.props.onClose}>Cancel</button>
                            </div>
                            {timestamp ? <div className="small mb-3">{timestamp}</div> : null}
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}