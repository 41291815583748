import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog } from '@fortawesome/free-solid-svg-icons';
import { faPatreon, faTwitch } from '@fortawesome/free-brands-svg-icons';

import Section from '../components/Section';

import api from '../util/api';

const linkregex = /[?#&\s]/g;

class Rewards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patreonShowLogin: false,
            patreonShowRewards: false,
            twitchShowLogin: false,
            twitchShowRewards: false,
            tier: 0,
            inputTwitch: '',
            inputYouTube: '',
            inputTwitter: '',
            thanks: false,
            refresh: false,
            guild: ''
        };
    }

    componentDidMount() {
        this.loadPatreon();
        this.loadTwitch();
    }

    componentDidUpdate(previous) {
        if (this.props.from === previous.from) return;
        this.loadPatreon();
        this.loadTwitch();
    }

    logout = (event) => {
        if (event) event.preventDefault(); // prevent form submission

        localStorage.removeItem('token.patreon');
        localStorage.removeItem('token.twitch');
        this.setState({ patreonShowLogin: true, patreonShowRewards: false, twitchShowLogin: true, twitchShowRewards: false });
    }

    async loadPatreon() {
        const json = localStorage.getItem('token.patreon');
        const token = json ? JSON.parse(json) : null;
        if (!token || !token.expires_at || (Date.now() > token.expires_at)) {
            localStorage.removeItem('token.patreon');
            this.setState({ patreonShowLogin: true });
            return;
        }

        const response = await api.post('rewards/patreon', { ...token, rewards: true, character: this.props.character });
        if (response.status !== 200) {
            localStorage.removeItem('token.patreon');
            this.setState({ patreonShowLogin: true });
            return;
        }

        const json2 = await response.json();
        this.setState({ patreonShowRewards: true, tier: json2.tier, thanks: json2.thanks, refresh: json2.refresh, inputTwitch: json2.twitch, inputYouTube: json2.youtube, inputTwitter: json2.twitter, guild: json2.guild });
    }

    async loadTwitch() {
        const json = localStorage.getItem('token.twitch');
        const token = json ? JSON.parse(json) : null;
        if (!token || !token.expires_at || (Date.now() > token.expires_at)) {
            localStorage.removeItem('token.twitch');
            this.setState({ twitchShowLogin: true });
            return;
        }

        const response = await api.post('rewards/twitch', { ...token, rewards: true, character: this.props.character });
        if (response.status !== 200) {
            localStorage.removeItem('token.twitch');
            this.setState({ twitchShowLogin: true });
            return;
        }

        const json2 = await response.json();
        this.setState({ twitchShowRewards: true, tier: json2.tier, thanks: json2.thanks, refresh: json2.refresh, inputTwitch: json2.twitch, inputYouTube: json2.youtube, inputTwitter: json2.twitter, guild: json2.guild });
    }

    patreonButton() {
        if (!this.state.patreonShowLogin) return null;

        const clientId = 'PUmGx-YqKHtA_A4z4h5s3LCXlpASgEArQYTVqXu_HbuqADlW8P9qDckw9uCJD57N';
        const stage = (window.location.hostname === 'dev.dataforazeroth.com') ? 'https://dev.dataforazeroth.com/redirect' : 'https://www.dataforazeroth.com/redirect';
        const state = 'patreon' + Date.now().toString(36).slice(-5);
        const url = 'https://www.patreon.com/oauth2/authorize?response_type=code&client_id=' + encodeURIComponent(clientId) + '&redirect_uri=' + encodeURIComponent(stage) + '&state=' + encodeURIComponent(state);

        localStorage.setItem('state.patreon', state);

        return (
            <div className="mr-2">
                <a href={url} className="btn btn-patreon text-nowrap"><FontAwesomeIcon icon={faPatreon} className="mr-2" />Claim Patreon Rewards</a>
            </div>
        );
    }

    twitchButton() {
        if (!this.state.twitchShowLogin) return null;

        const scope = (localStorage.getItem('scope.twitch') === 'true');

        const clientId = 'ffd4lf57uy229os2p5ybszs74c2dlu';
        const stage = (window.location.hostname === 'dev.dataforazeroth.com');
        const redir = stage ? 'https://dev.dataforazeroth.com/redirect' : 'https://www.dataforazeroth.com/redirect';
        const state = 'twitch' + Date.now().toString(36).slice(-5);
        const url = 'https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=' + encodeURIComponent(clientId) + '&redirect_uri=' + encodeURIComponent(redir) + '&state=' + encodeURIComponent(state) + (scope ? '&scope=channel:read:subscriptions' : '');

        localStorage.setItem('state.twitch', state);

        return (
            <div className="mr-2">
                <a href={url} className="btn btn-twitch text-nowrap"><FontAwesomeIcon icon={faTwitch} className="mr-2" />Claim Twitch Rewards</a>
            </div>
        );
    }

    rewardThanksOn = async (event) => {
        return this.rewardThanks(event, true);
    }

    rewardThanksOff = async (event) => {
        return this.rewardThanks(event, false);
    }

    rewardThanks = async (event, thanks) => {
        if (event) event.preventDefault(); // prevent form submission

        const payload = { rewards: true, thanks };
        if (await this.saveRewards(payload)) this.setState({ thanks });
    }

    rewardRefreshOn = async (event) => {
        return this.rewardRefresh(event, true);
    }

    rewardRefreshOff = async (event) => {
        return this.rewardRefresh(event, false);
    }

    rewardRefresh = async (event, refresh) => {
        if (event) event.preventDefault(); // prevent form submission

        const payload = { rewards: true, refresh };
        if (await this.saveRewards(payload)) this.setState({ refresh });
    }

    rewardGuild = async (event) => {
        if (event) event.preventDefault(); // prevent form submission

        const payload = {
            rewards: true,
            guild: document.getElementById('rewardguild').value
        };

        if (!payload.guild) {
            alert('Please select a guild');
            return;
        }
        if (await this.saveRewards(payload)) this.setState({ guild: payload.guild });
    }

    rewardLinks = async (event) => {
        if (event) event.preventDefault(); // prevent form submission

        const payload = {
            rewards: true,
            twitch: this.state.inputTwitch,
            youtube: this.state.inputYouTube,
            twitter: this.state.inputTwitter
        };

        if (await this.saveRewards(payload)) alert('Thank you! Your streamer links have been updated.');
    }

    async saveRewards(payload) {
        for (let type of [ 'patreon', 'twitch' ]) {
            if (!localStorage.getItem('token.' + type)) continue;

            const token = JSON.parse(localStorage.getItem('token.' + type));
            const response = await api.post('rewards/' + type, { ...payload, ...token });
            if (response.status !== 200) {
                alert('An unknown error occurred. Please try again in a few minutes.');
                return false;
            }
        }
        return true;
    }

    handleTwitch = (event) => {
        this.setState({ inputTwitch: event.target.value.replace(linkregex, '') });
    }

    handleYouTube = (event) => {
        this.setState({ inputYouTube: event.target.value.replace(linkregex, '') });
    }

    handleTwitter = (event) => {
        this.setState({ inputTwitter: event.target.value.replace(linkregex, '') });
    }

    render() {
        if (this.state.patreonShowLogin && this.state.twitchShowLogin) {
            return (
                <div className="d-flex flex-wrap justify-content-center m-3">
                    {this.patreonButton()}
                    {this.twitchButton()}
                </div>
            );
        }

        if (!this.state.patreonShowRewards && !this.state.twitchShowRewards) {
            // still loading
            return (
                <div className="d-flex flex-wrap justify-content-center m-3">
                    <span className="p-2 bar rounded text-center"><FontAwesomeIcon icon={faCog} spin /> Loading Rewards...</span>
                </div>
            );
        }

        const rewards = [];

        if (this.state.tier === 0) {
            if (this.state.patreonShowRewards) {
                rewards.push(
                    <div key="rewards0">
                        <h5>No Patreon Subscription Found</h5>
                        <p>Your Patreon account is not eligible for any rewards. It may take some time for Patreon to process your pledge. Please contact me directly on Patreon if this is a mistake.</p>
                        <a href="https://www.patreon.com/shoogen" target="external" className="btn btn-patreon text-nowrap"><FontAwesomeIcon icon={faPatreon} className="mr-2" />Become a Patron</a>
                    </div>
                );
            } else {
                rewards.push(
                    <div key="rewards0">
                        <h5>No Twitch Subscription Found</h5>
                        <p>Your Twitch account is not eligible for any rewards. Please contact me directly on Twitch if this is a mistake.</p>
                        <a href="https://twitch.tv/shoogen" target="external" className="btn btn-twitch text-nowrap"><FontAwesomeIcon icon={faTwitch} className="mr-2" />Subscribe</a>
                    </div>
                );
            }
        }

        if (this.state.tier >= 1) {
            rewards.push(
                <div key="rewards1">
                    <h5>Thank You</h5>
                    <p>Your main character will be shown on the <i>Thank You</i> page. This reward is currently {this.state.thanks ? 'enabled' : 'disabled'}.</p>
                    <div className="text-center">
                        {this.state.thanks ?
                            <button className="btn btn-primary" onClick={this.rewardThanksOff}>Turn Off</button> :
                            <button className="btn btn-primary" onClick={this.rewardThanksOn}>Turn On</button>
                        }
                    </div>
                </div>
            );
        }

        if (this.state.tier >= 2) {
            rewards.push(
                <div key="rewards2">
                    <h5>Character Refreshes</h5>
                    <p>Your characters will be automatically refreshed. Please make sure <i>Display Only Character Achievements to Others</i> is not used on any character, or the background task will not be able to detect them.
                    This reward is currently {this.state.refresh ? 'enabled' : 'disabled'}.</p>
                    <div className="text-center">
                        {this.state.refresh ?
                            <button className="btn btn-primary" onClick={this.rewardRefreshOff}>Turn Off</button> :
                            <button className="btn btn-primary" onClick={this.rewardRefreshOn}>Turn On</button>
                        }
                    </div>
                </div>
            );
        }

        if (this.state.tier >= 5) {
            rewards.push(
                <div key="rewards5">
                    <h5>Guild Refreshes</h5>
                    <p>Please select which guild you would like to have automatically refreshed. You may change this at any time.</p>
                    <div className="d-flex justify-content-center">
                        <div>
                            <select id="rewardguild" className="form-control" defaultValue={this.state.guild}>
                                <option key="empty"></option>
                                {this.props.guilds?.map(guild => <option key={guild.key} value={guild.key}>{guild.realm}-{guild.name}</option>)}
                            </select>
                        </div>
                        <div className="ml-1">
                            <button className="btn btn-primary" onClick={this.rewardGuild}>{this.state.guild ? <span>Claimed<FontAwesomeIcon icon={faCheck} className="mx-1" /></span> : 'Claim' }</button>
                        </div>
                    </div>
                </div>
            );
        }
        
        if (this.state.tier >= 1) {
            if (this.state.patreonShowRewards) {
                rewards.push(<div key="discord"><h5>Discord</h5><p>Please set up your rewards by connecting your Discord account inside Patreon.</p></div>);
            } else {
                rewards.push(<div key="discord"><h5>Discord</h5><p>Please set up your rewards by connecting your Twitch account inside Discord.</p></div>);
            }
        }

        if (this.state.tier >= 1) {
            rewards.push(
                <div key="rewardl">
                    <h5>Streamer Links</h5>
                    <p>The following links will be shown on your character's profile page and leaderboard rankings. You may leave any link blank if you do not want it shown.</p>
                    <div className="col-lg-6 col-md-8 mx-auto">
                        <div className="input-group mb-1">
                            <div className="input-group-prepend">
                                <span className="input-group-text">https://twitch.tv/</span>
                            </div>
                            <input type="text" className="form-control" value={this.state.inputTwitch || ''} onChange={this.handleTwitch} />
                        </div>
                        <div className="input-group mb-1">
                            <div className="input-group-prepend">
                                <span className="input-group-text">https://youtube.com/</span>
                            </div>
                            <input type="text" className="form-control" value={this.state.inputYouTube || ''} onChange={this.handleYouTube} />
                        </div>
                        <div className="input-group mb-1">
                            <div className="input-group-prepend">
                                <span className="input-group-text">https://twitter.com/</span>
                            </div>
                            <input type="text" className="form-control" value={this.state.inputTwitter || ''} onChange={this.handleTwitter} />
                        </div>
                        <div className="text-center">
                            <button className="btn btn-primary" onClick={this.rewardLinks}>Save</button>
                        </div>
                    </div>
                </div>
            );
        }

        const buttons =
            <div className={'my-auto form-inline ' + (window.bootstrap.md.matches ? 'ml-auto' : 'd-flex')}>
                <div className={window.bootstrap.md.matches ? '' : 'mr-auto'}> </div>
                <div><button className="btn btn-primary" onClick={this.logout}>Hide</button></div>
            </div>;

        return (
            <Section title={this.state.patreonShowRewards ? 'Patreon Rewards' : 'Twitch Rewards'} buttons={buttons}>
                <div className="small">
                    <p>
                        Thank you for your support! Below is a list of your rewards with instructions on claiming them.
                        If any rewards are incorrect or not working properly, please contact me directly on {this.state.patreonShowRewards ? 'Patreon' : 'Twitch'} and I will be happy to set them up for you.
                    </p>
                    {rewards}
                </div>
            </Section>
        );
    }
}

export default Rewards;