import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import utils from '../util/utils';

const regex = /[^0-9]+/g;

class Paging extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jump: false,
            input: 1
        };
    }

    go = (num) => {
        if (num < 0) num = 0;
        if (num >= this.props.totalSize) num = this.props.totalSize - 1;
        if (num >= utils.MAXPAGE) num = utils.MAXPAGE - 1;

        this.props.onClick(num, this.props.pageSize);
        this.setState({ jump: false });
    }

    goStart = () => {
        this.go(0);
    }

    goPrevious = () => {
        this.go(this.props.start - this.props.pageSize);
    }

    goNext = () => {
        this.go(this.props.end);
    }

    goLast = () => {
        let last = this.props.totalSize - (this.props.totalSize % this.props.pageSize);
        if (last === this.props.totalSize) last -= this.props.pageSize; // better way to do this?

        this.go(last);
    }

    jump = () => {
        this.setState({ jump: !this.state.jump, input: this.props.start + 1 });
    }

    submit = (event) => {
        if (event) event.preventDefault(); // prevent form submission
        this.go(parseInt(this.state.input) - 1);
        return false;
    }

    focus = (event) => {
        event.target.select();
    }

    change = (event) => {
        this.setState({ input: event.target.value.replace(regex, '') });
    }

    size = (event) => {
        this.props.onClick(this.props.start, parseInt(event.target.value));
        this.setState({ jump: false });
    }

    noop = (event) => {
        if (event) event.preventDefault(); // prevent form submission
        return false;
    }

    render() {
        const pageSizes = this.props.pageSizes || [];
        if (this.state.jump) {
            if (!pageSizes.includes(this.props.pageSize)) {
                pageSizes.push(this.props.pageSize);
                pageSizes.sort((a, b) => (a - b));
            }
        }

        return (
            <>
                <div className="text-center">
                    <div className="btn-group">
                        <button type="button" disabled={this.props.start === 0} className="btn btn-primary" onClick={this.goStart}><FontAwesomeIcon icon={faAngleDoubleLeft} /></button>
                        <button type="button" disabled={this.props.start === 0} className="btn btn-primary" onClick={this.goPrevious}><FontAwesomeIcon icon={faAngleLeft} /></button>
                        <button type="button" className="btn btn-primary" onClick={this.jump}>{utils.formatScore(this.props.start+1)} - {utils.formatScore(this.props.end)}{this.props.totalSize ? ' of ' + utils.formatScore(this.props.totalSize) : null}</button>
                        <button type="button" disabled={(this.props.end >= utils.MAXPAGE) || (this.props.end >= this.props.totalSize)} className="btn btn-primary" onClick={this.goNext}><FontAwesomeIcon icon={faAngleRight} /></button>
                        <button type="button" disabled={!this.props.totalSize || (this.props.end >= this.props.totalSize)} className="btn btn-primary" onClick={this.goLast}><FontAwesomeIcon icon={faAngleDoubleRight} /></button>
                    </div>
                </div>

                <form className="form-inline justify-content-center m-0" onSubmit={this.submit}>
                    {this.state.jump ?
                        <div className="input-group w-auto mt-2">
                            <div className="input-group-prepend">
                                <button type="button" className="btn btn-primary" disabled>Jump to</button>
                            </div>
                            <input type="text" className="form-control" value={this.state.input} size={5} maxLength={5} autoFocus={true} onFocus={this.focus} onChange={this.change} />
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-primary input-group-btn"><FontAwesomeIcon icon={faAngleRight} /></button>
                            </div>
                        </div>
                    : null}
                </form>

                <form className="form-inline justify-content-center m-0" onSubmit={this.noop}>
                    {this.state.jump && this.props.pageSizes ?
                        <div className="input-group w-auto mt-2">
                            <div className="input-group-prepend">
                                <button type="button" className="btn btn-primary" disabled>Page size</button>
                            </div>
                            <select id="pagesize" className="form-control" value={this.props.pageSize} onChange={this.size}>{pageSizes.map(p => <option key={p} value={p}>{p}</option>)}</select>
                        </div>
                    : null}
                </form>
            </>
        );
    }
}

export default Paging;